import styled, { keyframes } from "styled-components";
import { Palette } from "../../theme/Palette";

const openAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Root = styled.div`
    background-color: ${Palette.White10};
    padding: 24px 30px;
    border-radius: 4px;
    text-align: left;
`;

interface IDetailsProps {
    readonly isOpen?: boolean;
}

export const Summary = styled.summary`
     {
        width: 100%;
        position: relative;
        cursor: pointer;
        font-size: 24px;
        font-weight: 700;
        list-style: none;
        outline: 0;
        user-select: none;
        color: ${Palette.White100};
    }
`;

export const Details = styled.details<IDetailsProps>`
    & {
        font-size: 16px;
        color: ${Palette.White50};
    }

    & ${Summary} ~ * {
        animation: ${openAnimation} 0.5s ease-in-out;
        font-size: 0.95rem;
    }

    ${Summary}:after {
        font-size: 32px;
        line-height: 30px;
        color: ${Palette.White80};
        content: "+";
        position: absolute;
        right: 0;
        font-weight: 400;
        transform-origin: center;
        transition: 200ms linear;
        transform: rotate(${({ isOpen }) => (isOpen === true ? 45 : 0)}deg);
    }
`;
