import React from "react";
import { Collapsible } from "../components/Collapsible/Collapsible";
import { useExperiment } from "../hooks/useExperiment";

const Page: React.FC<React.PropsWithChildren<unknown>> = () => {
    const { groupId, id } = useExperiment();
    return (
        <React.Fragment>
            <p>Experiment: {id}</p>
            <p>Group: {groupId}</p>
            <p>{process.env.GATSBY_BUILD}</p>
            <Collapsible title="Some title">Some children</Collapsible>
        </React.Fragment>
    );
};

export default Page;
