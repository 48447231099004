import * as React from "react";
import { useCallback, useState } from "react";
import { Details, Root, Summary } from "./Collapsible.styles";

interface IProps {
    readonly title: string;
    readonly isInitiallyOpen?: boolean;
}

export const Collapsible: React.FC<React.PropsWithChildren<IProps>> = ({ children, title, isInitiallyOpen = false }) => {
    const [isOpen, setIsOpen] = useState(isInitiallyOpen);
    const onClicked: React.FormEventHandler<HTMLDetailsElement> = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);
    return (
        <Root>
            <Details onClick={onClicked} isOpen={isOpen}>
                <Summary>{title}</Summary>
                {children}
            </Details>
        </Root>
    );
};
