import { LocalStorageKey } from "../utilities/LocalStorageKey";

interface IExperimentConfig {
    readonly schemaVersion: 1;
    readonly endDate: string;
    readonly id: string;
    readonly groupId: "control" | "test";
}
const areExperimentsDisabled = true;

const getRunningExperiment = (): IExperimentConfig | null => {
    if (areExperimentsDisabled) {
        return null;
    }
    const coinFlip = Math.floor(Math.random() * 2);
    const randomGroup = coinFlip === 0 ? "control" : "test";
    return {
        id: "experiment/some-experiment",
        endDate: new Date(Date.parse("05 Dec 2022 00:12:00 GMT")).toISOString(),
        schemaVersion: 1,
        groupId: randomGroup,
    };
};

const initialExperimentConfig = getRunningExperiment();

const getExperimentConfigValue = (): IExperimentConfig | null => {
    if (typeof window !== "undefined") {
        if (areExperimentsDisabled) {
            return null;
        }
        const rawExperimentData = window.localStorage.getItem(LocalStorageKey.ExperimentData);
        const experimentData = JSON.parse(rawExperimentData ?? "null") as IExperimentConfig | null;
        if (experimentData === null) {
            return initialExperimentConfig;
        }

        const endDate = experimentData.endDate;
        if (endDate === undefined || endDate < new Date().toISOString()) {
            return null;
        }
        if (initialExperimentConfig !== null) {
            const hasSchemaExpired = experimentData.schemaVersion < initialExperimentConfig.schemaVersion;
            const isNewerExperimentRunning =
                initialExperimentConfig.id !== experimentData.id || experimentData.endDate < initialExperimentConfig.endDate;
            if (hasSchemaExpired) {
                return initialExperimentConfig;
            }
            if (isNewerExperimentRunning) {
                return initialExperimentConfig;
            }
        }

        return experimentData;
    }
    return null;
};

export const getExperimentConfig = (): IExperimentConfig | null => {
    const value = getExperimentConfigValue();
    if (typeof window !== "undefined") {
        window.localStorage.setItem(LocalStorageKey.ExperimentData, JSON.stringify(value));
    }
    return value;
};
interface IExperiment {
    readonly id: string | null;
    readonly groupId: "control" | "test" | null;
}
export const useExperiment = (): IExperiment => {
    const experiment = getExperimentConfigValue();
    const groupId = experiment?.groupId ?? null;
    const id = experiment?.id ?? null;

    return { id, groupId };
};
